import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: null,
        snackbar: {
            show: false,
            timeout: 2000,
            color: 'green',
            message: ''
        },
        modules: [
            {
                name: "AutoMod",
                description: "Everyone loves a clean and moderated chat! Netty can handle this for you with automated punishments for violating any rule! Note: This module is not completed yet but it's ready to use some basic features.",
                key: "automod",
                icon: window.location.origin + "/NettyAutoMod.png"
            },
            {
                name: "Mario Kart war calculator",
                description: "If you are a Mario Kart player this feature is for you! Especially for competitive players. During a 6v6 clanwar Netty can automatically calculate the points for both participating teams and even display them on your livestream using a browser source! At the end Netty will hand you out a detailed graph about the war!",
                key: "mariokart",
                icon: window.location.origin + "/NettyMarioKart.png"

            },
            {
                name: "AutoRole",
                description: "Automatically assigns a specified role to every new joining member on the server. Straightforward to everyone!",
                key: "autorole",
                icon: window.location.origin + "/NettyAutoRole.png"

            },
            {
                name: "Birthdays",
                description: "Let's face it, everyone forgets birthdays. Netty doesn't! Netty can post and automatically update a list of the birthdays of all members on your server. Furthermore you can optionally enable notifications on birthdays and assign a role to the happy birthday-users. Note: This currently works very slow. Will be fixed!",
                key: "birthday",
                icon: window.location.origin + "/NettyBirthday.png"

            },
            {
                name: "Reaction roles",
                description: "A really handy feature that allows users to self-assign and revoke their roles by clicking on a reaction under a message.",
                key: "reactionroles",
                icon: window.location.origin + "/NettyReactionRole.png"

            },
            {
                name: "ZenQuotes",
                description: "Need some motivation? With this module Netty will send a nice little quote in a channel once a day. (5am CET)",
                key: "zenquote",
                icon: window.location.origin + "/NettyQuote.png"

            },
            {
                name: "YouTube Notifications",
                description: "Netty sends a message when your favorite youtuber uploads a video or starts a livestream.",
                key: "youtube",
                icon: window.location.origin + "/NettyYoutube.png"

            },
            {
                name: "Twitch Notifications",
                description: "Never forget your favorite streamers livestreams with Netty sending you a information when a stream starts.",
                key: "twitch",
                icon: window.location.origin + "/NettyTwitch.png"

            },
            {
                name: "Twitter Notifications",
                description: "Post tweets in specific channels. Allows filtering text, retweets and answers and sending them to different channels!",
                key: "twitter",
                icon: window.location.origin + "/NettyTwitter.png"

            }
        ]
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {}
})
