<template>
  <div>
    <h1>{{ this.moduleInfo.name }}</h1>
    <v-container v-show="isLoaded">
      <v-row>
        <v-col cols="12" xs="12" lg="8" class="grey-background rounded-l">
          <v-container>
            <v-row v-for="rule in rules" :key="rule.RuleKey">
              <v-col cols="12">
                <h3>{{ rule.RuleKey }}</h3>
              </v-col>
              <v-col class="ml-15" cols="12" v-for="config in rule.Configs" :key="config.Key">
                <v-container>
                  <v-row>
                    <v-col md="3" cols="12">
                      <span>{{ config.Key }}</span>
                    </v-col>
                    <v-col md="9" cols="12">
                      <input class="textfield" type="number" v-model="config.ConfiguratedValue"
                             v-if="config.Type === 'IntValueOnly'" min="0" max="10000" :disabled="progress.isBlocked">
                      <select class="textfield" v-model="config.ConfiguratedValue" v-if="config.Type === 'ActionValue'"
                              :disabled="progress.isBlocked">
                        <option value="DO_NOTHING">Do Nothing</option>
                        <option value="WARN">Warn</option>
                        <option value="DELETE">Delete message</option>
                        <option value="DELETE AND NOTIFY">Delete message and notify user</option>
                        <option value="">Unset</option>
                      </select>
                      <select class="textfield" v-model="config.ConfiguratedValue"
                              v-if="config.Type === 'BoolValueOnly'" :disabled="progress.isBlocked">
                        <option value="TRUE">Yes</option>
                        <option value="FALSE">No</option>
                        <option value="">Unset</option>
                      </select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn class="button" v-on:click="saveData()">Save changes</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="1">
          <p></p>
        </v-col>
        <v-col xs="12" lg="3" cols="12" class="grey-background rounded-sm">
          <h2>{{ this.server.name }}</h2>
          <p></p>
          <div class="align-text-center w100">
            <v-avatar size="128">
              <img
                  :src="server.icon">
            </v-avatar>
          </div>
          <p><br></p>
          <p>{{ this.moduleInfo.description }}</p>
          <p>Note: This module is not completed yet, but it's ready to use some basic features.</p>
        </v-col>
      </v-row>
    </v-container>
    <div id="loader" v-show="!isLoaded || progress.isBlocked">
      <Loader class="loader"></Loader>
    </div>
  </div>
</template>

<script>

import Loader from '../../components/Loader.vue'

export default {
  data() {
    return {
      isLoaded: false,
      server: {
        name: "",
        icon: "",
        id: null
      },
      moduleInfo: {
        name: "",
        description: ""
      },
      rules: null,
      progress: {
        isBlocked: false,
      },
      firstItems: ["Do Nothing", "Warn", "Delete Message", "Delete message and notify user", "Unset"]
    }
  },
  methods: {
    init() {
      let token = this.$store.state.token;
      if (token) {

        let id = this.$route.params.id;
        fetch(`${process.env.VUE_APP_BACKEND}/AutoMod/` + id, {
          method: 'get',
          headers: {"Authorization": "Bearer " + token},
          credentials: 'include'
        })
            .then(data => data.json())
            .then(data => {
              this.rules = data
              this.isLoaded = true;
            });

        fetch(`${process.env.VUE_APP_BACKEND}/Guild/` + id, {
          method: 'get',
          headers: {"Authorization": "Bearer " + token},
          credentials: 'include'
        })
            .then(data => {
              if (data.status !== 200) {
                this.$router.push("/servers");
              }
              return data;
            })
            .then(data => data.json())
            .then(data => {
              if (data.Name == null) {
                this.$router.push("/servers");
              }
              this.server.name = data.Name;
              this.server.icon = data.IconUrl;
              this.server.id = id;
            });
      } else {
        this.$router.push("/");
      }
    },
    loadModuleInfo() {
      let module = this.$store.state.modules.filter(module => module.key == "automod")[0];
      this.moduleInfo.name = module.name;
      this.moduleInfo.description = module.description;
    },
    saveData() {
      this.progress.isBlocked = true;
      let token = this.$store.state.token;
      let id = this.$route.params.id;

      fetch(`${process.env.VUE_APP_BACKEND}/AutoMod/Save/` + id, {
        method: 'post',
        headers: {"Authorization": "Bearer " + token},
        credentials: 'include',
        body: JSON.stringify({rules: this.rules})
      })
          .then(data => data.json())
          .then(data => {
            this.$store.state.snackbar.show = true;
            if (data.success) {
              this.progress.isBlocked = false;
              this.$store.state.snackbar.color = "green";
              this.$store.state.snackbar.message = "Saved changes";
            } else {
              this.$store.state.snackbar.color = "red";
              this.$store.state.snackbar.message = "Could not save changes";
            }
          })
    }
  },
  components: {
    Loader
  },
  async created() {
    document.title = "Netty-Bot - Modules - AutoMod";
    this.loadModuleInfo();
    this.init();
  }
}

</script>

<style>

.button {
  float: right;
  width: 216px;
  height: 42px !important;
  border-radius: 50px !important;
  background-color: rgba(35, 253, 255, 0.8) !important;
}

.button:hover {
  box-shadow: 0 0 50px 20px #0ff; /* outer cyan */
}
</style>