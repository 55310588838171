<template>
  <v-app id="app">
    <ul class="circles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>

    <v-container fluid class="pa-10">
      <v-row>
        <v-col xs="11" sm="10" md="11" v-on:click="hideMenu">
          <p id="homeLink" v-on:click="$router.push('/').catch(() => {})">Netty-Bot.com</p>
        </v-col>
        <v-col xs="1" sm="2" md="1" class="text-right">
          <v-btn icon v-on:click="avatarClick">
            <v-avatar
                size="64px">
              <img
                  :src="avatarUrl">
            </v-avatar>
          </v-btn>
        </v-col>
      </v-row>
      <div id="view" v-on:click="hideMenu">
        <div class="pt-5">
          <router-view v-if="isLoaded"></router-view>
        </div>
      </div>
      <div id="menucontainer" ref="menucontainer">
        <div id="menu" v-show="this.displayMenu">
          <v-btn v-on:click="displayMenu = false;$router.push('/').catch(() => {})" width="100%">Home</v-btn>
          <v-btn v-on:click="displayMenu = false;$router.push('/servers').catch(() => {})" width="100%">My Servers
          </v-btn>
          <v-btn width="100%" class="blue" onclick="window.location.href='https://tipeeestream.com/brainyxs/donation'">
            Donate
          </v-btn>
          <v-btn width="100%" v-on:click="displayMenu = false;$router.push('/about').catch(() => {})">About</v-btn>
          <v-btn width="100%" class="red" v-on:click="logout">Logout</v-btn>
        </div>
      </div>
    </v-container>
    <!--    Spacer for the footer overlay -->
    <p></p>
    <p></p>
    <!--    End of Spacer-->
    <div id="footer">

      <p style="margin: 0;">© Copyright 2022 BrainyXS | Bot created by Manuel Schneider | Website by Manuel Schneider
        and Caner Kaya | <a
            target="_blank" href="https://github.com/BrainyXS/Netty-Bot">Source Code</a></p>
    </div>

    <div id="loadingOverlay" v-show="!isLoaded">
      <Loader></Loader>
    </div>

    <v-snackbar id="snackbar" v-model="$store.state.snackbar.show" bottom :color="$store.state.snackbar.color" :timeout="$store.state.snackbar.timeout">
        {{ $store.state.snackbar.message }}
        <template v-slot:action="{ attrs }">
          <v-btn dark text @click="$store.state.snackbar.show = false" v-bind="attrs">Dismiss</v-btn>
        </template>
    </v-snackbar>
  </v-app>
</template>
<script setup>

import {ref, onMounted} from "vue"
import autoAnimate from "@formkit/auto-animate"

const menucontainer = ref() // we need a DOM node

onMounted(() => {
  autoAnimate(menucontainer.value) // thats it!
})
</script>

<script>
import Loader from "./components/Loader.vue"

export default {
  data() {
    return {
      name: "Netty-Bot.com",
      displayMenu: false,
      avatarUrl: "https://media.istockphoto.com/vectors/profile-placeholder-image-gray-silhouette-no-photo-vector-id1016744004?k=20&m=1016744004&s=612x612&w=0&h=Z4W8y-2T0W-mQM-Sxt41CGS16bByUo4efOIJuyNBHgI=",
      isLoaded: false
    }
  },
  components: {
    Loader
  },
  methods: {
    hideMenu() {
      this.displayMenu = false;
    },
    avatarClick() {
      if (this.$store.state.token) {
        this.displayMenu = !this.displayMenu;
      } else {
        fetch(`${process.env.VUE_APP_BACKEND}/Login`)
            .then(data => data.json())
            .then(data => window.location.href = data.loginUrl);
      }
    },
    loaded() {
      let token = this.$cookies.get("token");
      let code = this.$route.query.token;
      if (token)
      {
        this.$store.state.token = token;
        code = token;
      }
      let header = {};
      if (code) {
        header = {"Authorization": "Bearer " + code};
      }
      fetch(`${process.env.VUE_APP_BACKEND}/`, {
        method: 'get',
        headers: header,
        credentials: 'include'
      })
          .then(data => data.json())
          .then(data => {
            if (data.authenticated) {
              this.avatarUrl = data.user.avatar_url
              this.$store.state.token = data.token;
              this.$cookies.set("token", data.token);
              let query = Object.assign({}, this.$route.query);
              delete query.token;
              this.$router.replace({query}).catch(() => {
              });
            }
            this.isLoaded = true;
          });
    },
    logout() {
      this.$cookies.remove("token");
      this.token = null;
      window.location = "https://netty-bot.com"
      fetch(`${process.env.VUE_APP_BACKEND}/Login/Logout`, {
        credentials: 'include'
      }).then(data => window.location.reload())
    }
  },
  async created() {
    this.loaded();
  }
}
</script>

<style>
#loadingOverlay {
  background-color: black;
  position: absolute;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.loader {
  position: fixed;
}

.align-text-center {
  text-align: center !important;
}

#app {
  font-family: Calibri;
  color: aliceblue;
  background: #4e54c8;
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
  width: 100%;
  height: 100vh;
  position: fixed;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  min-width: 100%;
  min-height: 100%;
  font-size: 200%;
}

#homeLink {
  cursor: pointer;
}

#menu {
  background-color: black;
  position: fixed;

  top: 12%;
  right: 2%;

  width: 100px;
}

#app > v-container {
  width: 100%;
  overflow-y: auto;
}

#menu > .v-btn {
  background-color: black;
  color: aliceblue;
  border-radius: 0;
}

#snackbar {
  margin-bottom: 6%;
}

#footer {
  position: fixed;
  background-color: darkslategrey;
  width: 100%;
  height: 10vh;
  bottom: 0px;
  font-size: 1.5vw;
  text-align: center;
  color: #a6a09f;

  display: flex;
  justify-content: center;
  align-items: center;
}

html {
  overflow-y: auto
}

.circles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.v-application--wrap {
  overflow-y: auto;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2) {
  left: 2%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 20%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

/* has to be set manually because Vuetify overrides this setting */
select {
  appearance: auto !important;;
}

.textfield {
  outline: none;
  border: 1px solid #d6d0d0;
  color: #d5d1d1;
  border-radius: 9px;
  padding: 3px;
  color: white !important;
}

.textfield * {
  color: white !important;

}

.textfield *::placeholder {
  color: white !important;
}

option {
  /* Whatever color  you want */
  background-color: #82caff;
}

</style>
