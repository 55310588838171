<template>
  <div>

    <v-container v-show="isLoaded">
      <v-row>
        <v-col cols="12" xs="12" lg="8" class="grey-background rounded-l">
          <h2>Modules</h2>
          <v-container>
            <v-row v-for="module in this.modules" :key="module.key">
              <v-container>
                <v-row>
                  <v-col cols="12" md="8">
                    <h3>{{ module.name }}</h3>
                  </v-col>
                  <v-col cols="12" md="4">
                    <div class="box-1" v-on:click="$router.push('/servers/'+server.id+'/'+module.key)">
                      <div class="btn btn-one">
                        <span>Configure</span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="2" class="text-center">
                    <img width="128px" :src="module.icon">
                  </v-col>
                  <v-col cols="12" md="10">
                    {{ module.description }}
                  </v-col>
                </v-row>
              </v-container>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="1">
          <p></p>
        </v-col>
        <v-col xs="12" lg="3" cols="12" class="grey-background rounded-sm">
          <h2>{{ this.server.name }}</h2>
          <p></p>
          <div class="align-text-center w100">
            <v-avatar size="128">
              <img
                  :src="server.icon">
            </v-avatar>
          </div>
          <p><br></p>
          <p>Change the settings for {{ this.server.name }}
            here.
            You can find all sorts of things here. Those settings will apply for this server.
          </p>
          <p>Note that it might take some time after you change any setting for it to be registered on our bot.</p>
          <p>If you like Netty, please consider <a href="https://tipeeestream.com/brainyxs/donation">supporting the
            project!</a> Donations makes it possible to keep this project alive!</p>
        </v-col>
      </v-row>
    </v-container>

    <div id="loader" v-show="!isLoaded">
      <Loader></Loader>
    </div>
  </div>

</template>

<script>
import Loader from "../components/Loader.vue"

export default {
  data() {
    return {
      isLoaded: false,
      server: {
        name: "",
        icon: "",
        id: null
      },
      modules: this.$store.state.modules
    }
  },
  methods: {
    init() {
      let token = this.$store.state.token;
      if (token) {
        fetch(`${process.env.VUE_APP_BACKEND}/Guild/` + this.$route.params.id, {
          method: 'get',
          headers: {"Authorization": "Bearer " + token},
          credentials: 'include'
        })
            .then(data => {
              if (data.status !== 200) {
                this.$router.push("/servers");
              }
              return data;
            })
            .then(data => data.json())
            .then(data => {
              if (data.Name == null) {
                this.$router.push("/servers");
              }
              this.isLoaded = true;
              this.server.name = data.Name;
              this.server.icon = data.IconUrl;
              this.server.id = this.$route.params.id;
            });
      } else {
        this.$router.push("/");
      }
    }
  },
  components: {
    Loader
  },
  async created() {
    document.title = "Netty-Bot - Modules";
    this.init();
  }
}

</script>

<style>

.btn {
  line-height: 50px;
  height: 50px;
  text-align: center;
  width: 250px;
  cursor: pointer;
  max-width: 100%;
}

.btn-one {
  color: #FFF;
  transition: all 0.3s;
  position: relative;
}

.btn-one span {
  transition: all 0.3s;
}

.btn-one::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: rgba(255, 255, 255, 0.5);
  border-bottom-color: rgba(255, 255, 255, 0.5);
  transform: scale(0.1, 1);
}

.btn-one:hover span {
  letter-spacing: 2px;
}

.btn-one:hover::before {
  opacity: 1;
  transform: scale(1, 1);
}

.btn-one::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  background-color: rgba(255, 255, 255, 0.1);
}

.btn-one:hover::after {
  opacity: 0;
  transform: scale(0.1, 1);
}


</style>