<template>
  <div>
    <h1>{{ this.moduleInfo.name }}</h1>
    <v-container>
      <v-row>
        <v-col cols="12" xs="12" lg="8">
          <v-container class="grey-background rounded-l" v-show="isRulesLoaded">
            <v-row ref="container" class="ma-5">
              <v-col cols="12" v-for="autoMod in autoMods" :key="autoMod.role" class="autoRole">
                Auto assigning role to EVERY user:&nbsp;&nbsp;&nbsp;&nbsp;
                <select v-model="autoMod.role" :value="autoMod.role" :disabled="isSaving" class="textfield">
                  <option v-for="role in roles" :key="role.Id" v-show="!autoMods.some(mod => mod.role == role.Id)" :value="role.Id" >{{ role.Name }}</option>
                </select>
                <font-awesome-icon v-on:click="remove(autoMod)" icon="fa-solid fa-trash" style="float: right"/>
              </v-col>
              <v-col v-if="this.roles.length == 0" id="noRolesMessage">Your server does not have any roles</v-col>
              <v-col v-if="this.roles.length != 0" v-show="this.autoMods.length != this.roles.length" v-on:click="add()" cols="12" class="autoRole create" style="text-align: center">
                <font-awesome-icon icon="fa-solid fa-plus"/>
              </v-col>
            </v-row>
            <v-row class="ma-5" v-if="this.roles.length != 0">
              <v-col cols="12" class="create" v-on:click="Save()">
                <font-awesome-icon icon="fa-regular fa-floppy-disk"
                                   style="float: right; color: aquamarine; scale: 1.5"/>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="1">
          <p></p>
        </v-col>
        <v-col xs="12" lg="3" cols="12" class="grey-background rounded-sm" v-show="isServerLoaded">
          <h2>{{ this.server.name }}</h2>
          <p></p>
          <div class="align-text-center w100">
            <v-avatar size="128">
              <img
                  :src="server.icon">
            </v-avatar>
          </div>
          <p><br></p>
          <p>{{ this.moduleInfo.description }}</p>
        </v-col>
      </v-row>
    </v-container>

    <div id="loader" v-show="!isServerLoaded || !isRulesLoaded || isSaving">
      <Loader class="loader"></Loader>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue"
import autoAnimate from "@formkit/auto-animate"

const container = ref()

onMounted(() => {
  autoAnimate(container.value)
})
</script>

<script>
import Loader from "@/components/Loader";

export default {
  data() {
    return {
      server: {
        name: "",
        icon: "",
        id: null
      },
      moduleInfo: {
        name: "",
        description: ""
      },
      autoMods: [],
      roles: [],
      isServerLoaded: false,
      isRulesLoaded: false,
      isSaving: false,
    }
  },

  methods: {
    init() {
      let token = this.$store.state.token;
      if (token) {

        let id = this.$route.params.id;
        fetch(`${process.env.VUE_APP_BACKEND}/Guild/` + id, {
          method: 'get',
          headers: {"Authorization": "Bearer " + token},
          credentials: 'include'
        })
            .then(data => {
              if (data.status !== 200) {
                this.$router.push("/servers");
              }
              return data;
            })
            .then(data => data.json())
            .then(data => {
              if (data.Name == null) {
                this.$router.push("/servers");
              }
              this.isServerLoaded = true;
              this.server.name = data.Name;
              this.server.icon = data.IconUrl;
              this.server.id = id;
            });
        fetch(`${process.env.VUE_APP_BACKEND}/AutoRole/GetRoles/` + id, {
          method: 'get',
          headers: {"Authorization": "Bearer " + token},
          credentials: 'include'
        })
            .then(data => data.json())
            .then(data => {
              this.roles = data
              this.initAutoRoles();
            });
      }
    },
    loadModuleInfo() {
      let module = this.$store.state.modules.filter(module => module.key == "autorole")[0];
      this.moduleInfo.name = module.name;
      this.moduleInfo.description = module.description;
    },
    add() {
      if (this.isSaving) {
        return;
      }
      let index = 0;
      for (var i = 0; i < this.roles.length; i++)
      {
          if (!this.autoMods.some(mod => mod.role == this.roles[i].Id))
          {
            index = i;
            break;
          }
      }

      this.autoMods.push({
        role: this.roles[index].Id,
        configId: 0
      });
    },
    Save() {
      if (this.isSaving) {
        return;
      }
      let token = this.$store.state.token;
      let id = this.$route.params.id;
      this.isSaving = true;
      let body = JSON.stringify(this.autoMods);
      this.autoMods = [];
      this.isRulesLoaded = false;
      fetch(`${process.env.VUE_APP_BACKEND}/AutoRole/SetAutoRoles/` + id, {
        method: 'post',
        headers: {"Authorization": "Bearer " + token},
        credentials: 'include',
        body: body
      })
          .then(data => {
            this.initAutoRoles();
            this.isSaving = false

            this.$store.state.snackbar.show = true;
            this.$store.state.snackbar.color = data.ok ? "green" : "red";
            this.$store.state.snackbar.message = data.ok ? "Saved changes" : "Could not save changes";
          });
    },
    initAutoRoles()
    {
      let id = this.$route.params.id;
      let token = this.$store.state.token;
      fetch(`${process.env.VUE_APP_BACKEND}/AutoRole/GetAutoRoles/` + id, {
        method: 'get',
        headers: {"Authorization": "Bearer " + token},
        credentials: 'include'
      })
          .then(data => data.json())
          .then(data => {
            this.autoMods = data;
            this.isRulesLoaded = true;
          });

    },
    remove(mod) {
      let index = this.autoMods.indexOf(mod);
      this.autoMods.splice(index, 1);
    }
  },
  components: {
    Loader
  },
  async created() {
    document.title = "Netty-Bot - Modules - AutoRole";
    this.loadModuleInfo();
    this.init();
  }
}
</script>

<style scoped>
.autoRole {
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 20px;
  margin-bottom: 10px;
}

.create {
  cursor: pointer;
}

#noRolesMessage {
  text-align: center;
}
</style>