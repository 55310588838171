<template>
  <div>
    <h1>{{ this.moduleInfo.name }}</h1>
    <v-container>
      <v-row>
        <v-col cols="12" xs="12" lg="8">
          <v-container class="grey-background rounded-l" v-show="isReactionRolesLoaded">
            <v-row>
              <v-col cols="12" v-for="reactionRole in reactionRoles" :key="reactionRole.Id">
                <v-container class="reactionRole">
                  <v-row>
                    <v-col cols="6">Reaction Role in Channel:</v-col>
                    <v-col cols="5">#{{ reactionRole.ChannelName }}</v-col>
                    <v-col cols="1" v-if="!reactionRole.IsUrlEmote">{{ reactionRole.Unicode }}</v-col>
                    <v-col cols="1" v-if="reactionRole.IsUrlEmote"><img
                        :src="reactionRole.Url + '?size=32&quality=lossless'"></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="0" md="1"></v-col>
                    <v-col cols="12" md="11">"{{ reactionRole.MessageContent }}"</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="2">Rolle:</v-col>
                    <v-col cols="12" md="9" class="green--text">{{ reactionRole.RoleName }}</v-col>
                    <v-col cols="12" md="1">
                      <font-awesome-icon v-on:click="remove(reactionRole)" icon="fa-solid fa-trash" style="float: right"/>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-container class="reactionRole">
                <v-row>
                  <v-col cols="12" md="4">NEW Reaction Role in Channel:</v-col>
                  <v-col cols="11" md="7">
                    <select v-model="newReactionRole.ChannelId" class="textfield">
                      <option v-for="tchannel in channel" :key="tchannel.ChannelId" :value="tchannel.ChannelId">
                        #{{ tchannel.ChannelName }}
                      </option>
                    </select>
                  </v-col>
                  <v-col cols="1">
                    <font-awesome-icon icon="fa-solid fa-caret-down" v-if="newReactionRole.Emoji == null"
                                       v-on:click="showEmotePicker = true;"/>
                    <img :src="newReactionRole.Emoji.ImageUrl" v-if="newReactionRole.Emoji != null" width="32px"
                         v-on:click="showEmotePicker = true;">
                    <div class=" emotePicker" v-show="showEmotePicker">
                      <v-container>
                        <v-row>
                          <v-text-field class="textfield white--text" placeholder="filter..." v-model="filter"
                                        v-on:input="changed()"></v-text-field>
                        </v-row>
                        <v-row>
                          <v-col cols="2" v-for="emote in filteredEmotes" :key="emote.ImageUrl"><img :src="emote.ImageUrl" width="100%"
                                                                               class="emote"
                                                                               v-on:click="emoteClicked(emote)">
                          </v-col>
                        </v-row>
                      </v-container>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">Message ID</v-col>
                  <v-col cols="12" md="8">
                    <input type="number" class="textfield white--text" v-model="newReactionRole.MessageId"
                           placeholder="123123123123" width="100%">
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">Role</v-col>
                  <v-col cols="12" md="7">
                    <select class="textfield" v-model="newReactionRole.RoleId">
                      <option v-for="role in roles" :value="role.Id" :key="role.Id">{{ role.Name }}</option>
                    </select>
                  </v-col>
                  <v-col cols="1" class="pointer" style="float: right" v-on:click="Save()">
                    <font-awesome-icon icon="fa-regular fa-floppy-disk"
                                       style="color: aquamarine; scale: 1.5"/>
                  </v-col>
                </v-row>
              </v-container>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="1">
          <p></p>
        </v-col>
        <v-col xs="12" lg="3" cols="12" class="grey-background rounded-sm" v-show="isServerLoaded">
          <h2>{{ this.server.name }}</h2>
          <p></p>
          <div class="align-text-center w100">
            <v-avatar size="128">
              <img
                  :src="server.icon">
            </v-avatar>
          </div>
          <p><br></p>
          <p>{{ this.moduleInfo.description }}</p>
        </v-col>
      </v-row>
    </v-container>
    <div id="loader" v-show="!isReactionRolesLoaded">
      <Loader class="loader"></Loader>
    </div>
  </div>
</template>

<script>
import Loader from '../../components/Loader.vue'

export default {
  data() {
    return {
      isServerLoaded: false,
      isReactionRolesLoaded: false,
      server: {},
      reactionRoles: [],
      roles: [],
      channel: [],
      emotes: [],
      newReactionRole: {
        ChannelId: null,
        Emoji: null,
        MessageId: null,
        RoleId: null
      },
      moduleInfo: {
        name: "",
        description: ""
      },
      showEmotePicker: false,
      filter: "",
      filteredEmotes: []
    }
  },
  methods: {
    init() {
      let token = this.$store.state.token;
      if (token) {

        let id = this.$route.params.id;
        fetch(`${process.env.VUE_APP_BACKEND}/Guild/` + id, {
          method: 'get',
          headers: {"Authorization": "Bearer " + token},
          credentials: 'include'
        })
            .then(data => {
              if (data.status !== 200) {
                this.$router.push("/servers");
              }
              return data;
            })
            .then(data => data.json())
            .then(data => {
              if (data.Name == null) {
                this.$router.push("/servers");
              }
              this.isServerLoaded = true;
              this.server.name = data.Name;
              this.server.icon = data.IconUrl;
              this.server.id = id;
            });
        fetch(`${process.env.VUE_APP_BACKEND}/ReactionRole/GetTextChannel/` + id, {
          method: 'get',
          headers: {"Authorization": "Bearer " + token},
          credentials: 'include'
        })
            .then(data => data.json())
            .then(data => {
              this.channel = data;
            });
        fetch(`${process.env.VUE_APP_BACKEND}/ReactionRole/GetAllEmoji/` + id, {
          method: 'get',
          headers: {"Authorization": "Bearer " + token},
          credentials: 'include'
        })
            .then(data => data.json())
            .then(data => {
              this.emotes = data;
              this.filteredEmotes = data;
            });
        fetch(`${process.env.VUE_APP_BACKEND}/AutoRole/GetRoles/` + id, {
          method: 'get',
          headers: {"Authorization": "Bearer " + token},
          credentials: 'include'
        })
            .then(data => data.json())
            .then(data => {
              this.roles = data
              this.newReactionRole.RoleId = data[0].Id;
            })
            .then(() => {
              this.LoadReactionRoles(this);
            });
      }
    },
    loadModuleInfo() {
      let module = this.$store.state.modules.filter(module => module.key == "reactionroles")[0];
      this.moduleInfo.name = module.name;
      this.moduleInfo.description = module.description;
    },
    changed() {
      if (this.filter == "") {
        this.filteredEmotes = this.emotes;
      } else {
        this.filteredEmotes = this.emotes.filter(emote => emote.Name.toLowerCase().includes(this.filter.toLowerCase()));
      }
    },
    emoteClicked(emote) {
      this.showEmotePicker = false;
      this.newReactionRole.Emoji = emote;
    },
    Save() {
      if (this.newReactionRole.RoleId == null || this.newReactionRole.ChannelId == null || this.newReactionRole.MessageId == null || this.newReactionRole.Emoji == null) {
        this.$store.state.snackbar.show = true;
        this.$store.state.snackbar.color = "red";
        this.$store.state.snackbar.message = "Please fill in all required data";
        return;
      }
      this.isReactionRolesLoaded = false;
      let token = this.$store.state.token;
      let id = this.$route.params.id;
      fetch(`${process.env.VUE_APP_BACKEND}/ReactionRole/SaveReactionRole/` + id, {
        method: 'post',
        headers: {"Authorization": "Bearer " + token},
        credentials: 'include',
        body: JSON.stringify(this.newReactionRole)
      })
          .then(() => {
            this.newReactionRole = {
              ChannelId: null,
              Emoji: null,
              MessageId: null,
              RoleId: null
            };
            this.LoadReactionRoles();
          })

    },
    LoadReactionRoles() {
      let token = this.$store.state.token;
      let id = this.$route.params.id;
      fetch(`${process.env.VUE_APP_BACKEND}/ReactionRole/GetReactionRoles/` + id, {
        method: 'get',
        headers: {"Authorization": "Bearer " + token},
        credentials: 'include'
      })
          .then(data => data.json())
          .then(data => {

            this.isReactionRolesLoaded = true;
            this.reactionRoles = data;
            this.reactionRoles.forEach(reactionRole => {
              const role = this.roles.find(r => {
                return r.Id == reactionRole.RoleId
              });
              reactionRole.RoleName = role.Name;
            })
          })

    },
    remove(reactionRole)
    {
      let token = this.$store.state.token;
      let id = this.$route.params.id;
      this.isReactionRolesLoaded = false;
      fetch(`${process.env.VUE_APP_BACKEND}/ReactionRole/DeleteReactionRole/` + id + "/" + reactionRole.Id, {
        method: 'post',
        headers: {"Authorization": "Bearer " + token},
        credentials: 'include',
      })
          .then(() => {
            this.LoadReactionRoles();
          })

    }
  },
  components: {
    Loader
  },
  async created() {
    document.title = "Netty-Bot - Modules - ReactionRole";
    this.loadModuleInfo();
    this.init();
  }
}

</script>

<style scoped>
.reactionRole {
  border: 2px black solid;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.36);
  margin-bottom: 5px;
}

.emotePicker {
  position: absolute;
  width: 20%;
  height: 28%;
  background-color: #000e19;
  overflow-y: scroll;
  z-index: 10;
}

.emote:hover {
  cursor: pointer;
}

option {
  /* Whatever color  you want */
  background-color: #82caff;
}

.pointer {
  cursor: pointer;
}

</style>