<template>
  <div>
    <h1>Mario Kart</h1>
    <v-container v-show="isLoaded">
      <v-row>
        <v-col class="grey-background rounded-l">
          <v-container>
            <v-row>
              <v-col cols="11">
                <h3 v-if="!isRenaming">{{ match.name }}</h3>
                <v-text-field class="nameField" v-model="match.name" v-if="isRenaming"/>
              </v-col>
              <v-col cols="1">
                <font-awesome-icon class="pointer" v-on:click="editName()" icon="fa-solid fa-pen-to-square"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2"/>
              <v-col cols="8">
                <iframe :src="this.chartUrl" style="width: 100%; aspect-ratio: 2" scrolling="no"/>
              </v-col>
              <v-col cols="2"/>
            </v-row>
            <v-row>
              <v-col cols="12">
                <i style="float: right">{{match.started}}</i>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <div id="loader" v-show="!this.isLoaded">
      <Loader class="loader"></Loader>
    </div>


  </div>

</template>

<script>
import Loader from "@/components/Loader";

export default {
  data() {
    return {
      isLoaded: false,
      match: null,
      chartUrl: null,
      isRenaming: false
    }
  },
  methods: {
    init() {
      let token = this.$store.state.token;
      if (token) {
        let id = this.$route.params.id;
        let gameId = this.$route.params.gameId;

        fetch(`${process.env.VUE_APP_BACKEND}/MarioKart/GetById/` + id + '/' + gameId, {
          method: 'get',
          headers: {"Authorization": "Bearer " + token},
          credentials: 'include'
        })
            .then(data => data.json())
            .then(data => {
              this.match = data;
              this.isLoaded = true;
            });

        fetch(`${process.env.VUE_APP_BACKEND}/MarioKart/GetChartUrl/` + id + '/' + gameId, {
          method: 'get',
          headers: {"Authorization": "Bearer " + token},
          credentials: 'include'
        })
            .then(data => data.text())
            .then(data => {
              this.chartUrl = data;
            });
      }
    },
    editName() {
      if (!this.isRenaming) {
        this.isRenaming = true;
      } else {
        this.isRenaming = false;
        let id = this.$route.params.id;
        let gameId = this.$route.params.gameId;
        fetch(`${process.env.VUE_APP_BACKEND}/MarioKart/ChangeTitle/` + id + '/' + gameId, {
          method: 'post',
          headers: {"Authorization": "Bearer " + this.$store.state.token},
          credentials: 'include',
          body: this.match.name
        })
            .then(() =>{
              this.$store.state.snackbar.show = true;
              this.$store.state.snackbar.color = "green";
              this.$store.state.snackbar.message = "Name updated";

            });
      }
    }
  },
  components: {
    Loader
  },
  async created() {
    this.init();
  }

}
</script>

<style>
.pointer {
  cursor: pointer;
}

.v-input {
  font-size: 150% !important;
}

.nameField input {
  color: white !important;
}
.nameField .v-input__slot:before {
  border-color: white !important;
}

</style>