<template>
  <v-container class="mkGame">
    <v-row>
      <v-col cols="12" md="11"><router-link :to="'/servers/' + this.serverId + '/mariokart/' + match.id">{{ this.match.name }}</router-link></v-col>
      <v-col cols="12" md="1">
        <div v-on:click="fav(match)" class="star">
          <font-awesome-icon icon="fa-solid fa-star" style="color: yellow"
                             v-show="this.match.isFavorited == 1"/>
          <font-awesome-icon icon="fa-solid fa-star" v-show="this.match.isFavorited == 0"/>
        </div>
      </v-col>
      <v-col cols="6">{{ this.match.status }}</v-col>
      <v-col cols="6" md="3">
        <i>{{ this.match.points }}&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;{{ this.match.enemyPoints }}</i>
      </v-col>
      <v-col cols="12" md="3"><i>Difference:&nbsp;&nbsp;{{ this.match.points - this.match.enemyPoints }}</i></v-col>
      <v-col cols="0" md="12"/>
      <v-col cols="12" md="2"><i>{{ this.match.started }}</i></v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'MarioKartGameItem',
  props: {
    fav: {},
    match: {},
    serverId: {}
  }
}

</script>
<style>
.mkGame {
  border: 2px black solid;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.36);
}

.mkGame i {
  font-size: 65%;
  color: grey;
}

.star {
  cursor: pointer;
}
</style>