<template>
  <div>
    <h1>{{ this.moduleInfo.name }}</h1>
    <v-container>
      <v-row>
        <v-col cols="12" xs="12" lg="8">
          <v-container class="grey-background rounded-l" v-show="isQuotesLoaded">
            <v-row v-show="this.zenQuotes.length != 0">
              <h3>Current registrations</h3>
              <v-col cols="12" v-for="quote in zenQuotes" :key="quote.RegistrationId" class="zenQuote mb-5">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="10">
                      <select v-model="quote.ChannelId" class="textfield">
                        <option v-for="tchannel in channels" :key="tchannel.ChannelId" :value="tchannel.ChannelId" v-show="!zenQuotes.some(zenQuote => zenQuote.ChannelId == tchannel.ChannelId)">
                          #{{ tchannel.ChannelName }}
                        </option>
                      </select>
                    </v-col>
                    <v-col cols="12" md="1">
                      <font-awesome-icon v-on:click="remove(quote)" icon="fa-solid fa-trash" style="float: right"/>
                    </v-col>
                    <v-col cols="12" md="1" v-on:click="Save(quote)">
                      <font-awesome-icon icon="fa-regular fa-floppy-disk"
                                         style="float: right; color: aquamarine; scale: 1.5"/>
                    </v-col>

                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <v-row v-show="this.zenQuotes.length != this.channels.length">
              <h3>New registrations</h3>
              <v-col cols="12" class="zenQuote mb-5">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="11">
                      <select v-model="newChannelId" class="textfield">
                        <option v-for="tchannel in channels" :key="tchannel.ChannelId" :value="tchannel.ChannelId" v-show="!zenQuotes.some(zenQuote => zenQuote.ChannelId == tchannel.ChannelId)">
                          #{{ tchannel.ChannelName }}
                        </option>
                      </select>
                    </v-col>
                    <v-col cols="12" md="1" v-on:click="SaveNew()">
                      <font-awesome-icon icon="fa-regular fa-floppy-disk"
                                         style="float: right; color: aquamarine; scale: 1.5"/>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>

            </v-row>
          </v-container>
        </v-col>
        <v-col cols="1">
          <p></p>
        </v-col>
        <v-col xs="12" lg="3" cols="12" class="grey-background rounded-sm" v-show="isServerLoaded">
          <h2>{{ this.server.name }}</h2>
          <p></p>
          <div class="align-text-center w100">
            <v-avatar size="128">
              <img
                  :src="server.icon">
            </v-avatar>
          </div>
          <p><br></p>
          <p>{{ this.moduleInfo.description }}</p>
        </v-col>
      </v-row>
    </v-container>

    <div id="loader" v-show="!isServerLoaded || !isQuotesLoaded || isSaving">
      <Loader class="loader"></Loader>
    </div>

  </div>
</template>

<script>
import Loader from "@/components/Loader";

export default {
  data() {
    return {
      isServerLoaded: false,
      server: {
        name: "",
        icon: "",
        id: null
      },
      moduleInfo: {
        name: "",
        description: ""
      },
      isQuotesLoaded: false,
      isSaving: false,
      zenQuotes: [],
      channels: [],
      newChannelId: null
    }
  },
  components:
      {
        Loader
      },
  methods: {
    init() {
      let token = this.$store.state.token;
      if (token) {

        let id = this.$route.params.id;
        fetch(`${process.env.VUE_APP_BACKEND}/Guild/` + id, {
          method: 'get',
          headers: {"Authorization": "Bearer " + token},
          credentials: 'include'
        })
            .then(data => {
              if (data.status !== 200) {
                this.$router.push("/servers");
              }
              return data;
            })
            .then(data => data.json())
            .then(data => {
              if (data.Name == null) {
                this.$router.push("/servers");
              }
              this.isServerLoaded = true;
              this.server.name = data.Name;
              this.server.icon = data.IconUrl;
              this.server.id = id;
            });
        fetch(`${process.env.VUE_APP_BACKEND}/ReactionRole/GetTextChannel/` + id, {
          method: 'get',
          headers: {"Authorization": "Bearer " + token},
          credentials: 'include'
        })
            .then(data => data.json())
            .then(data => {
              this.channels = data;
              this.InitQuotes();
            });
      }
    },
    InitQuotes() {
      let token = this.$store.state.token;
      let id = this.$route.params.id;
      this.isQuotesLoaded = false;
      fetch(`${process.env.VUE_APP_BACKEND}/ZenQuote/Load/` + id, {
        method: 'get',
        headers: {"Authorization": "Bearer " + token},
        credentials: 'include'
      })
          .then(data => data.json())
          .then(data => {
            this.zenQuotes = data;
            this.isQuotesLoaded = true;
          })
      this.newChannelId = null;
    },
    loadModuleInfo() {
      let module = this.$store.state.modules.filter(module => module.key == "zenquote")[0];
      this.moduleInfo.name = module.name;
      this.moduleInfo.description = module.description;
    },
    Save(zenQuote) {
      let token = this.$store.state.token;
      let id = this.$route.params.id;
      this.isSaving = true;
      this.isQuotesLoaded = false;
      fetch(`${process.env.VUE_APP_BACKEND}/ZenQuote/Update/` + id, {
        method: 'post',
        headers: {"Authorization": "Bearer " + token},
        credentials: 'include',
        body: JSON.stringify(zenQuote)
      })
          .then((data) => {
            this.InitQuotes();
            this.isSaving = false;

            this.$store.state.snackbar.show = true;
            this.$store.state.snackbar.color = data.ok ? "green" : "red";
            this.$store.state.snackbar.message = data.ok ? "Saved changes" : "Could not save changes";
          });
    },
    SaveNew() {
      if (this.newChannelId == null) {
        return;
      }
      let token = this.$store.state.token;
      let id = this.$route.params.id;
      this.isSaving = true;
      this.isQuotesLoaded = false;
      fetch(`${process.env.VUE_APP_BACKEND}/ZenQuote/Save/` + id, {
        method: 'post',
        headers: {"Authorization": "Bearer " + token},
        credentials: 'include',
        body: JSON.stringify({
          ChannelId: this.newChannelId
        })
      })
          .then((data) => {
            this.InitQuotes();
            this.isSaving = false;

            this.$store.state.snackbar.show = true;
            this.$store.state.snackbar.color = data.ok ? "green" : "red";
            this.$store.state.snackbar.message = data.ok ? "Added quote subscription" : "Could not add quote subscription";
          });

    },
    remove(quote) {
      let token = this.$store.state.token;
      let id = this.$route.params.id;
      this.isSaving = true;
      this.isQuotesLoaded = false;
      fetch(`${process.env.VUE_APP_BACKEND}/ZenQuote/Delete/` + id, {
        method: 'post',
        headers: {"Authorization": "Bearer " + token},
        credentials: 'include',
        body: JSON.stringify(quote)
      })
          .then((data) => {
            this.InitQuotes();
            this.isSaving = false;

            this.$store.state.snackbar.show = true;
            this.$store.state.snackbar.color = data.ok ? "green" : "red";
            this.$store.state.snackbar.message = data.ok ? "Removed quote subscription" : "Could not remove quote subscription";
          });
    }
  },
  async created() {
    document.title = "Netty-Bot - Modules - ZenQuote";
    this.loadModuleInfo();
    this.init();
  }
}
</script>

<style scoped>
.zenQuote {
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 20px;
  border: black 2px solid;
}

option {
  /* Whatever color  you want */
  background-color: #82caff;
}

.pointer {
  cursor: pointer;
}

</style>