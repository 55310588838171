import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ServersView from '../views/Servers'
import ServerView from '../views/Server'
import AboutView from '../views/About'
import AutoModView from '../views/configs/AutoMod.vue'
import ErrorView from '../views/ErrorView.vue'
import MarioKartView from '../views/configs/MarioKart.vue'
import MarioKartGameView from '../views/configs/MarioKartGame.vue'
import AutoRoleView from '../views/configs/AutoRole.vue'
import BirthdaysView from '../views/configs/Birthdays.vue'
import ReactionRoleView from '../views/configs/ReactionRole'
import ZenQuoteView from '../views/configs/Zenquote'
import YoutubeRegistrationView from '../views/configs/YoutubeNotification'
import TwitchRegistrationView from '../views/configs/TwitchNotification'
import TwitterRegistrationView from '../views/configs/TwitterNotification'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/servers',
        name: 'Servers',
        component: ServersView
    },
    {
        path: '/servers/:id',
        name: "Server",
        component: ServerView
    },
    {
        path: '/servers/:id/automod',
        name: "AutoMod",
        component: AutoModView
    },
    {
        path: '/about',
        name: "About",
        component: AboutView
    },
    {
        path: '/servers/:id/mariokart',
        name: "MarioKart",
        component: MarioKartView
    },
    {
      path: '/servers/:id/autorole',
      name: "AutoRole",
      component: AutoRoleView
    },
    {
        path: '/servers/:id/mariokart/:gameId',
        name: "MarioKartGame",
        component: MarioKartGameView
    },
    {
        path: '/servers/:id/birthday',
        name: "Birthdays",
        component: BirthdaysView
    },
    {
        path: '/servers/:id/reactionroles',
        name: "ReactionRole",
        component: ReactionRoleView
    },
    {
        path: '/servers/:id/zenquote',
        name: "ZenQuotes",
        component: ZenQuoteView
    },
    {
        path: '/servers/:id/youtube',
        name: "Youtube",
        component: YoutubeRegistrationView
    },
    {
        path: '/servers/:id/twitch',
        name: "Twitch",
        component: TwitchRegistrationView
    },
    {
        path: '/servers/:id/twitter',
        name: "Twitter",
        component: TwitterRegistrationView
    },
    {
        path: '*',
        name: "404",
        component: ErrorView
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    template: '<router-view/>',
    routes
})

export default router
