<template>
    <v-container>
        <div class="rowContainer">
            <v-row id="topRow">
                <h1 style="font-size: 6rem;">Error 404</h1>
                <h3 style="font-size: 3rem;">Page Not Found</h3>
            </v-row>

            <v-row id="bottomRow">
                <p style="font-size: 1.6rem;">Couldn't find the page you're looking for?</p>
                <a href="/">Home</a>
                <a href="/servers">My Servers</a>
                <a href="https://tipeeestream.com/brainyxs/donation">Donate</a>
                <a href="/about">About</a>
            </v-row>
        </div>
    </v-container>
</template>

<script>
export default {
  created() {
    document.title = "Netty-Bot - 404";
  }
}
</script>

<style>

.rowContainer
{
    height: 500px;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

#topRow, #bottomRow
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#bottomRow a
{
    color: #abbfdb;
}

@media screen and (max-height: 700px)
{
    .rowContainer
    {
        height: auto;
        flex-direction: row;
    }
}

</style>