<template>
  <div>
    <div>My Servers</div>
    <v-container>
      <v-row ref="guildContainer">
        <v-col v-for="server in servers" cols="12" xs="12" sm="12" md="6" lg="4" xl="3" v-bind:key="server.id">
          <v-card class="server-card">
            <v-img
                height="200px"
                :src="server.icon">
              <div class="unblur">
                <v-card-title class="white--text mt-8 background">
                  <v-avatar size="56">
                    <img
                        :src="server.icon">
                  </v-avatar>
                  <p class="ml-3 servertext" >
                    {{ server.name }}
                  </p>
                </v-card-title>
                <div class="buttons">
                  <p v-if="$vuetify.breakpoint.mdAndUp"></p>
                  <v-btn v-on:click="navigate(server.id)" class="srv-btn settings" v-if="server.status == 'Normal'">
                    Settings
                  </v-btn>
                  <v-btn class="srv-btn add" v-if="server.status == 'NotAdded'"
                         onclick="window.location.href='https://invite.netty-bot.com/';">Add now!
                  </v-btn>
                  <v-btn class="srv-btn added"
                         v-if="server.status == 'MissingPermission'">Already added!
                  </v-btn>
                </div>
              </div>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <div id="loader" v-show="!isLoaded">
      <Loader></Loader>
    </div>
  </div>
</template>
<script setup>

import {ref, onMounted} from "vue"
import autoAnimate from "@formkit/auto-animate"

const guildContainer = ref() // we need a DOM node

onMounted(() => {
  autoAnimate(guildContainer.value) // thats it!
})
</script>

<script>
import Loader from "../components/Loader.vue"

export default {

  data() {
    return {
      servers: [],
      isLoaded: false
    }
  },
  components: {
    Loader
  },
  methods: {
    init() {
      let token = this.$store.state.token;
      if (token) {
        fetch(`${process.env.VUE_APP_BACKEND}/Guilds`, {
          method: 'get',
          headers: {"Authorization": "Bearer " + token},
          credentials: 'include'
        })
            .then(data => data.json())
            .then(data => {
              this.servers = data;
              this.isLoaded = true;
            });
      } else {
        this.$router.push("/");
      }
    },
    navigate(guild) {
      let to = "/servers/" + guild;
      this.$router.push(to);
    }
  },

  async created() {
    document.title = "Netty-Bot - Servers";
    this.init();
  }
}
</script>
<style>

.unblur {
  backdrop-filter: blur(10px);
  margin-top: -32px;
  width: 100%;
  height: 100%;
}

.background {
  background-color: rgba(92, 92, 92, 0.25);
}

.buttons {
  width: 100%;
  height: 100%;
  text-align: center;
}

.srv-btn {
  width: 80%;
  height: 35% !important;
  border-radius: 50px !important;
}

.add {
  background-color: rgba(35, 253, 255, 0.8) !important;
}

.added {
  background-color: rgba(85, 80, 80, 0.8) !important;
  color: white !important;
  pointer-events: none

}

.settings {
  background-color: rgba(255, 243, 243, 0.60) !important;
}

.server-card {
  outline: white 2px solid;
  transition: all .2s ease-in-out;

}

.server-card:hover {
  transform: scale(1.1);
  outline: deepskyblue 3px solid;
}

.servertext
{
  width: 60%;
}
.add:hover {
  box-shadow: 0 0 50px 20px #0ff; /* outer cyan */
}

.settings:hover {
  box-shadow: 0 0 50px 20px white; /* outer cyan */
}
</style>