<template>
  <div>
    <h1>{{ this.moduleInfo.name }}</h1>
    <v-container>
      <v-row>
        <v-col cols="12" xs="12" lg="8">
          <v-container v-show="isLoaded" class="grey-background rounded-l">
            <div class="ma-2">
              <v-row>
                <h3>Current registrations</h3>
                <v-col cols="12" v-for="registration in twitchNotifications" :key="registration.RegistrationId"
                       class="item mb-5">
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="4">Twitch Channel Name</v-col>
                      <v-col cols="12" md="8">
                        <input class="textfield" type="text" v-model="registration.TwitchChannelName">
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4">Discord-Channel</v-col>
                      <v-col cols="12" md="8">
                        <select v-model="registration.ChannelId" class="textfield">
                          <option v-for="tchannel in channel" :key="tchannel.ChannelId" :value="tchannel.ChannelId">
                            #{{ tchannel.ChannelName }}
                          </option>
                        </select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4">Message</v-col>
                      <v-col cols="12" md="6">
                        <input class="textfield" type="text" v-model="registration.Message">
                      </v-col>
                      <v-col cols="1">
                        <font-awesome-icon v-on:click="remove(registration)" icon="fa-solid fa-trash"
                                           style="float: right;scale: 1.5"/>
                      </v-col>

                      <v-col cols="1">
                        <font-awesome-icon v-on:click="Save(registration)" icon="fa-regular fa-floppy-disk"
                                           style="color: aquamarine; scale: 1.5; float: right"/>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
              <v-row>
                <h3>New registrations</h3>
                <v-col cols="12" class="item mb-5">
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="4">Twitch Channel Name</v-col>
                      <v-col cols="12" md="8">
                        <input class="textfield" type="text" v-model="newNotification.TwitchChannelName">
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4">Discord-Channel</v-col>
                      <v-col cols="12" md="8">
                        <select v-model="newNotification.ChannelId" class="textfield">
                          <option v-for="tchannel in channel" :key="tchannel.ChannelId" :value="tchannel.ChannelId">
                            #{{ tchannel.ChannelName }}
                          </option>
                        </select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4">Message</v-col>
                      <v-col cols="12" md="6">
                        <input class="textfield" type="text" v-model="newNotification.Message">
                      </v-col>
                      <v-col cols="2">
                        <font-awesome-icon v-on:click="SaveNew()" icon="fa-regular fa-floppy-disk"
                                           style="color: aquamarine; scale: 1.5; float: right"/>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>

              </v-row>
            </div>
          </v-container>
        </v-col>
        <v-col cols="1">
          <p></p>
        </v-col>
        <v-col xs="12" lg="3" cols="12" class="grey-background rounded-sm" v-show="isServerLoaded">
          <h2>{{ this.server.name }}</h2>
          <p></p>
          <div class="align-text-center w100">
            <v-avatar size="128">
              <img
                  :src="server.icon">
            </v-avatar>
          </div>
          <p><br></p>
          <p>{{ this.moduleInfo.description }}</p>
          <br>
          <br>
          <br>
          <p>ⓘ Hint: You can ping a role by adding "&lt;&amp;" followed by a role-id and "&gt;" at the end to the message
            property!</p>
        </v-col>
      </v-row>
    </v-container>

    <div id="loader" v-show="!isServerLoaded || !isLoaded">
      <Loader class="loader"></Loader>
    </div>

  </div>
</template>

<script>
import Loader from "@/components/Loader";

export default {
  data() {
    return {
      isServerLoaded: false,
      server: {
        name: "",
        icon: "",
        id: null
      },
      moduleInfo: {
        name: "",
        description: ""
      },
      channel: [],
      twitchNotifications: [],
      isLoaded: false,
      newNotification:
          {
            TwitchChannelId: null,
            ChannelId: null,
            Message: "A new livestream has started!"
          }

    }
  },
  components:
      {
        Loader
      },
  methods:
      {
        init() {
          let token = this.$store.state.token;
          if (token) {

            let id = this.$route.params.id;
            fetch(`${process.env.VUE_APP_BACKEND}/Guild/` + id, {
              method: 'get',
              headers: {"Authorization": "Bearer " + token},
              credentials: 'include'
            })
                .then(data => {
                  if (data.status !== 200) {
                    this.$router.push("/servers");
                  }
                  return data;
                })
                .then(data => data.json())
                .then(data => {
                  if (data.Name == null) {
                    this.$router.push("/servers");
                  }
                  this.isServerLoaded = true;
                  this.server.name = data.Name;
                  this.server.icon = data.IconUrl;
                  this.server.id = id;
                });
            this.initRegistrations();
            fetch(`${process.env.VUE_APP_BACKEND}/ReactionRole/GetTextChannel/` + id, {
              method: 'get',
              headers: {"Authorization": "Bearer " + token},
              credentials: 'include'
            })
                .then(data => data.json())
                .then(data => {
                  this.channel = data;
                });
          }
        },
        loadModuleInfo() {
          let module = this.$store.state.modules.filter(module => module.key == "twitch")[0];
          this.moduleInfo.name = module.name;
          this.moduleInfo.description = module.description;
        },
        initRegistrations() {
          this.isLoaded = false;
          let token = this.$store.state.token;
          let id = this.$route.params.id;
          this.newNotification = {
            TwitchChannelId: null,
            ChannelId: null,
            Message: "A new livestream has started"
          }

          fetch(`${process.env.VUE_APP_BACKEND}/Twitch/GetRegistrations/` + id, {
            method: 'get',
            headers: {"Authorization": "Bearer " + token},
            credentials: 'include'
          })
              .then(data => data.json())
              .then(data => {
                this.twitchNotifications = data;
                this.isLoaded = true;
              })

        },
        Save(registration) {
          let token = this.$store.state.token;
          let id = this.$route.params.id;
          this.isLoaded = false;
          fetch(`${process.env.VUE_APP_BACKEND}/Twitch/SaveRegistration/` + id, {
            method: 'post',
            headers: {"Authorization": "Bearer " + token},
            credentials: 'include',
            body: JSON.stringify(registration)
          })
              .then(() => {
                this.initRegistrations();
              })

        },
        remove(registration) {
          let token = this.$store.state.token;
          let id = this.$route.params.id;
          this.isLoaded = false;
          fetch(`${process.env.VUE_APP_BACKEND}/Twitch/DeleteRegistration/` + id, {
            method: 'post',
            headers: {"Authorization": "Bearer " + token},
            credentials: 'include',
            body: registration.RegistrationId
          })
              .then(() => {
                this.initRegistrations();
              })
        },
        SaveNew() {
          if (this.newNotification.TwitchChannelName == null || this.newNotification.ChannelId == null) {
            this.$store.state.snackbar.show = true;
            this.$store.state.snackbar.color = "red";
            this.$store.state.snackbar.message = "Please fill in all required data";
            return;
          }
          let token = this.$store.state.token;
          let id = this.$route.params.id;
          this.isLoaded = false;
          fetch(`${process.env.VUE_APP_BACKEND}/Twitch/SaveRegistration/` + id, {
            method: 'post',
            headers: {"Authorization": "Bearer " + token},
            credentials: 'include',
            body: JSON.stringify(this.newNotification)
          })
              .then(() => {
                this.initRegistrations();
              })
        }
      },
  async created() {
    document.title = "Netty-Bot - Modules - Twitch Notifications";
    this.loadModuleInfo();
    this.init();
  }
}
</script>

<style scoped>
.item {
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 20px;
  border: black 2px solid;
}

option {
  /* Whatever color  you want */
  background-color: #82caff;
}

input {
  width: 100%;
}
</style>