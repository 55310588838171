<template>
  <v-container>
    <v-row>
      <v-col class="grey-background">
        <h2>Welcome to Netty - the only Discord bot you'll ever need!</h2>
        <img src="https://build.brainyxs.com/Files/Netty/Netty-transparent.png" class="float-right" width="256px"
             v-if="!mobile"/>
        <p></p>
        <p>Netty is a free-to-use feature-rich Discord bot with all the features a user and moderator need. We follow
          Discord's
          standards and completely rely on the new and modern Slashcommands.
        </p>
        <p></p>
        <h3>Features</h3>
        <p>Netty comes with all the features a user and admin could wish for!</p>
        <v-container class="features">
          <v-row>
            <v-col xs="12" sm="12" md="6" lg="4" xl="3" v-for="feature in this.features" :key="feature.name">
              <h4>{{ feature.name }}</h4>
              <p></p>
              <img class="float-left mr-5" :src="feature.icon" width="156px">
              <p>{{ feature.description }}</p>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  data() {
    return {
      features: this.$store.state.modules
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm;
    },
  },
  created() {
    document.title = "Netty-Bot";
  }
}

</script>


<style>
.grey-background {
  font-size: 80%;
  background-color: rgba(77, 77, 78, 0.5);
}

.features p {
  font-size: 80%
}

</style>