<template>
  <v-container>
    <v-row>
      <v-col class="grey-background">
        <h2>There was an idea</h2>
        <p>Netty was founded with a simple idea in mind: To Build the BEST available Discord-Bot out there. Not only
          this, it is important to us, that EVERYONE should be able to use this bot for FREE. Netty is, always was and
          will be 100% FREE without any premium features or membership whatsoever. Hosting a bot costs money, so I
          really appreciate any <a href="https://tipeeestream.com/brainyxs/donation">DONATION</a>. It helps keeping this
          project alive but having our idea in mind, we will not be able to grant you any extra features or priviliges.
          Netty will stay free for everyone FOREVER. </p>
        <p>Also Netty's source-code is <a href="https://github.com/BrainyXS/Netty-Bot">PUBLICLY AVAILABLE</a>.
          Feel free to modify the code to your needs and host the bot yourself. I also appreciate PULL REQUESTS</p>
        <br>
        <h1>The team behind Netty</h1>
        <h2>Development</h2>
        <h3>BrainyXS - Main Developer</h3>
        <p></p>
        <v-container>
          <v-row>
            <v-col md="10" cols="12">
              <p>Hey, my name is Manuel Schneider and I am a Swiss developer, youtuber and streamer. On the 16. October
                2021,
                I started this project on a <a href="https://www.youtube.com/watch?v=_D2taCxMlns">Twitch livestream</a>.
                Never
                have I tought that this will be one of the biggest projects of my life. I had never planned a bot of this
                size,
                I
                just added more and more features and even built this website. You can visit my personal <a
                    href="https://brainyxs.com">Website</a>, my <a href="https://youtube.brainyxs.com">Youtube</a> or <a
                    href="https://twitch.tv/brainyxs">Twitch</a>-Channel. If you are interested in other open source
                projects
                I implemented, you can find them over at my <a href="https://github.com/BrainyXS">GitHub</a>
              </p>
            </v-col>
            <v-col md="2" cols="12">
              <img class="b-img" src="../../public/Brainy.jpeg">
            </v-col>
          </v-row>
        </v-container>
        <p></p>
        <h3>CK17 - Web developer</h3>
        <v-container>
          <v-row>
            <v-col md="2" cols="12">
              <img class="b-img" src="https://unavatar.io/twitter/DEINAVATAR">
            </v-col>
            <v-col md="10" cols="12">
              <p>Coming Soon</p>
            </v-col>
          </v-row>
        </v-container>
        <h2>Artwork & Design</h2>
        <h3>ShiroDarq - Artist</h3>


        <v-container>
          <v-row>
            <v-col md="10" cols="12">
              <p>ShiroDarq is the main artist. Without her Netty would not have a face! She did not only draw the main icon
                and
                profile image of Netty but created every icon on this website by hand!</p>
              <p>You can find her on <a href="https://twitter.com/ShiroDarq">Twitter</a></p>
            </v-col>
            <v-col md="2" cols="12">
              <img class="b-img" src="../../public/shirodarq.jpg">
            </v-col>
          </v-row>
        </v-container>
        <h2>Translation</h2>
        <h3>Izzy - Translation</h3>
        <v-container>
          <v-row>
            <v-col md="2" cols="12">
              <img class="b-img" src="../../public/izzy.png">
            </v-col>
            <v-col md="10" cols="12">
              <p>With her knowledge of the Spanish language, Izzy was kind enough to help translating the bot into said
                language. </p>
              <p>You can find her on <a href="https://twitter.com/zerosoulespada">Twitter</a></p>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    init() {
      let token = this.$store.state.token;

      if (!token) {
        this.$router.push("/");
      } 
    }
  },

  async created() {
    document.title = "Netty-Bot - About";
    this.init();
  }
}
</script>

<style>

.b-img {
  float: right;
  width: 100%;
  border-radius: 9px;
}

</style>