<template>
  <div>
    <h1>{{ this.moduleInfo.name }}</h1>
    <v-container v-show="isLoaded">
      <v-row>
        <v-col cols="12" xs="12" lg="8" class="grey-background rounded-l">
          <h2>Saved matches</h2>
          <v-container>
            <v-row ref="container">
              <v-col v-for="match in favorites" :key="match.id" cols="12">
                <MarioKartGameItem :fav="fav" :match="match" :server-id="serverId"/>
              </v-col>
            </v-row>
          </v-container>
          <h2>Past matches</h2>
          <v-container>
            <v-row ref="container2">
              <v-col v-for="match in matches" :key="match.id" cols="12">
                <MarioKartGameItem :fav="fav" :match="match" :server-id="serverId"/>
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-row>
              <v-col cols="0" md="4"/>
              <v-col cols="12" md="4">
                <v-btn v-if="this.isLoading" style="width: 100%" disabled>
                  Loading...
                </v-btn>
                <v-btn v-on:click="loadMore()" v-if="!this.isLoading" style="width: 100%">Load More</v-btn>
              </v-col>
              <v-col cols="0" md="4"/>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="1">
          <p></p>
        </v-col>
        <v-col xs="12" lg="3" cols="12" class="grey-background rounded-sm">
          <h2>{{ this.server.name }}</h2>
          <p></p>
          <div class="align-text-center w100">
            <v-avatar size="128">
              <img
                  :src="server.icon">
            </v-avatar>
          </div>
          <p><br></p>
          <p>{{ this.moduleInfo.description }}</p>
          <p>Use /race to get started and /mkcomplete to complete a match. Here, you can save graphs of past
            matches!</p>
        </v-col>
      </v-row>
    </v-container>

    <div id="loader" v-show="!isLoaded">
      <Loader class="loader"></Loader>
    </div>


  </div>
</template>

<script setup>
import {onMounted, ref} from "vue"
import autoAnimate from "@formkit/auto-animate"
import MarioKartGameItem from "@/views/configs/MarioKartGameItem";

const container = ref()
const container2 = ref()

onMounted(() => {
  autoAnimate(container.value)
  autoAnimate(container2.value)
})
</script>

<script>
import Loader from "@/components/Loader";

export default {
  data() {
    return {
      isLoaded: false,
      server: {
        name: "",
        icon: "",
        id: null
      },
      moduleInfo: {
        name: "",
        description: ""
      },
      serverId: this.$route.params.id,
      page: 0,
      isLoading: false,
      favorites: [],
      matches: []
    }
  },
  methods: {
    init() {
      let token = this.$store.state.token;
      if (token) {

        let id = this.serverId;
        fetch(`${process.env.VUE_APP_BACKEND}/Guild/` + id, {
          method: 'get',
          headers: {"Authorization": "Bearer " + token},
          credentials: 'include'
        })
            .then(data => {
              if (data.status !== 200) {
                this.$router.push("/servers");
              }
              return data;
            })
            .then(data => data.json())
            .then(data => {
              if (data.Name == null) {
                this.$router.push("/servers");
              }
              this.isLoaded = true;
              this.server.name = data.Name;
              this.server.icon = data.IconUrl;
              this.server.id = id;
            });
        fetch(`${process.env.VUE_APP_BACKEND}/MarioKart/Favs/` + id + "/", {
          method: 'get',
          headers: {"Authorization": "Bearer " + token},
          credentials: 'include'

        })
            .then(data => data.json())
            .then(data => {
              data.forEach(d => {
                    this.favorites.push(d);
                  }
              )
            });
        fetch(`${process.env.VUE_APP_BACKEND}/MarioKart/Matches/` + id + "/" + "0", {
          method: 'get',
          headers: {"Authorization": "Bearer " + token},
          credentials: 'include'

        })
            .then(data => data.json())
            .then(data => {
              data.forEach(d => {
                    this.matches.push(d);
                  }
              )
            });


      } else {
        this.$router.push("/");
      }
    },
     loadModuleInfo() {
      let module = this.$store.state.modules.filter(module => module.key == "mariokart")[0];
      this.moduleInfo.name = module.name;
      this.moduleInfo.description = module.description;
    },
    fav(match) {
      if (match.isFavorited == 1) {
        let index = this.favorites.indexOf(match);
        this.favorites.splice(index, 1);
        match.isFavorited = 0;
      } else {
        this.favorites.push(match);
        match.isFavorited = 1;
      }
      fetch(`${process.env.VUE_APP_BACKEND}/MarioKart/SetFav/` + this.serverId + "/" + match.id, {
        method: 'get',
        headers: {"Authorization": "Bearer " + this.$store.state.token},
        credentials: 'include'

      })
    },
    loadMore() {
      this.isLoading = true;
      this.page++;
      fetch(`${process.env.VUE_APP_BACKEND}/MarioKart/Matches/` + this.serverId + "/" + this.page, {
        method: 'get',
        headers: {"Authorization": "Bearer " + this.$store.state.token},
        credentials: 'include'

      })
          .then(data => data.json())
          .then(data => {
            data.forEach(d => {
                  this.matches.push(d);
                }
            )
            this.isLoading = false;
          });

    }
  },
  components: {
    Loader
  },
  async created() {
    document.title = "Netty-Bot - Modules - MarioKart";
    this.loadModuleInfo();
    this.init();
  }
}
</script>

<style>
.mkGame {
  border: 2px black solid;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.36);
}

.mkGame i {
  font-size: 65%;
  color: grey;
}

.mkGame .col {
  margin-bottom: -10px;
}

.star {
  cursor: pointer;
}

</style>