<template>
  <div class="grey-background rounded-l">
    <div class="ma-5">
      <h2>{{ this.moduleInfo.name }}</h2>
      <h3>Great News!</h3>
      <p>We are currently working on a new system to display birthdays in your server</p>
      <p>Unfortunately, you have to wait a bit longer</p>
      <p>A web-based dashboard will be available once the new system is completed!</p>
      <!-- <p>{{ this.moduleInfo.description }}</p> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      moduleInfo: {
        name: "",
        description: ""
      }
    }
  },
  methods: {
    loadModuleInfo() {
      let module = this.$store.state.modules.filter(module => module.key == "birthday")[0];
      this.moduleInfo.name = module.name;
      this.moduleInfo.description = module.description;
    }
  },
  async created() {
    document.title = "Netty-Bot - Modules - Birthdays";
    this.loadModuleInfo();
  }
}
</script>

<style scoped>

</style>